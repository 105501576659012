







import Vue from 'vue';
export default Vue.extend({
  mounted () {
    if (this.$route.query && this.$route.query.email) {
      this.email = (this.$route.query.email as string);
    }
  },
  data () {
    return {
      'email': ''
    };
  },
  'methods': {
    verifyEmail () {
      this.axios.get('data/verification-token', {
        'params': {
          'email': this.email
        }
      }).then(({ data }) => {
        this.$router.push({
          'name': 'CreatePassword',
          'query': {
            'token': data.result.token
          }
        });
      });
    }
  }
});
